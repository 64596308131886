const modal = document.querySelector('.white_modal');

if (modal) {
    const modal_error = modal.querySelector('.row.error');
    const phoneInput = modal.querySelector('#id_phone');
    const mainContent = document.querySelector('.main-section-without-modal');
    const breadCrumbs = document.querySelector('.header-crumbs-list');

    function scrollToModal() {
        if (modal.classList.contains('full-view')) {
            modal.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        modal.querySelector('.close')?.addEventListener('click', e => {
            e.preventDefault();
            modal.style.display = 'none';
            if (mainContent) {
                mainContent.style.display = 'block';
                breadCrumbs.style.display = 'flex';
            }
        });

        document.querySelectorAll('.white_modal_holler').forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                modal.style.display = 'flex';
                if (mainContent) {
                    mainContent.style.display = 'none';
                    breadCrumbs.style.display = 'none';
                }
                scrollToModal();
            });
        });

        phoneInput.addEventListener('keyup', e => {
            if (e.target.value.length > 17) {
                $(phoneInput).data('correct', 1);
            } else {
                $(phoneInput).data('correct', 0);
            }
            console.log(phoneInput.dataset.correct);
        })

        document.querySelector('.white_modal .reg-btn-wrapper .btn').addEventListener('click', e => {
            e.preventDefault();
            // send phone on check
            modal_error.textContent = '';
            const phone = phoneInput.value;
            const hasErrFields = modal.querySelectorAll('.empty_field').length > 0;
            let dontReenableBtn = false;
            modal_error.textContent = '';
            modal.querySelectorAll('.rfield_error').forEach(el => {
                el.style.visibility = 'hidden';
            })
            if (phone.length > 0 && !hasErrFields) {
                e.target.disabled = true;
                e.target.querySelector('.loader').style.display = 'inline-block';
                fetch('/reg_slick_check', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        'phone': phone,
                    })
                }).then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        modal_error.textContent = "Ошибка проверки телефона, статус " + resp.status;
                    }
                }).then(data => {
                    if (data.result === "ok") {
                        const recaptchaResponse = grecaptcha.getResponse();
                        if (!recaptchaResponse || recaptchaResponse.length === 0) {
                            modal_error.textContent = "Подтвердите, что вы не робот";
                            scrollToModal();
                            return;
                        }

                        // post if ok
                        const lastName = modal.querySelector('#id_last_name').value;
                        const firstName = modal.querySelector('#id_first_name').value;
                        const midName = modal.querySelector('#id_second_name').value;
                        const eventId = modal.querySelector('#event_id').value;

                        const form = document.createElement('form');
                        form.method = 'POST';
                        form.action = '/reg_slick_do';

                        const inputLN = document.createElement('input');
                        inputLN.type = 'hidden';
                        inputLN.name = 'last_name';
                        inputLN.value = lastName;
                        form.appendChild(inputLN);

                        const inputFN = document.createElement('input');
                        inputFN.type = 'hidden';
                        inputFN.name = 'first_name';
                        inputFN.value = firstName;
                        form.appendChild(inputFN);


                        const inputMN = document.createElement('input');
                        inputMN.type = 'hidden';
                        inputMN.name = 'middle_name';
                        inputMN.value = midName;
                        form.appendChild(inputMN);

                        const inputPhone = document.createElement('input');
                        inputPhone.type = 'hidden';
                        inputPhone.name = 'phone';
                        inputPhone.value = phone;
                        form.appendChild(inputPhone);

                        const inputEvent = document.createElement('input');
                        inputEvent.type = 'hidden';
                        inputEvent.name = 'event_id';
                        inputEvent.value = eventId;
                        form.appendChild(inputEvent);

                        const recaptchaInput = document.createElement('input');
                        recaptchaInput.type = 'hidden';
                        recaptchaInput.name = 'g-recaptcha-response';
                        recaptchaInput.value = recaptchaResponse;
                        form.appendChild(recaptchaInput);

                        document.querySelectorAll('input[name="tags[]"]:checked').forEach(checkbox => {
                            const hiddenInput = document.createElement('input');
                            hiddenInput.type = 'hidden';
                            hiddenInput.name = 'tags[]';
                            hiddenInput.value = checkbox.value;
                            form.appendChild(hiddenInput);
                        });

                        document.body.appendChild(form);

                        dontReenableBtn = true;
                        form.submit();
                    } else if (data.result === "exists") {
                        modal_error.textContent = "Телефонный номер уже зарегистрирован, используйте другой";
                        scrollToModal();
                    } else if (data.result === "empty") {
                        modal_error.textContent = "Не указан телефонный номер";
                        scrollToModal();
                    }
                }).catch(err => {
                    console.log(err);
                    modal_error.textContent = "Возникла техническая ошибка, попробуйте позднее";
                    scrollToModal();
                }).finally(() => {
                    if (!dontReenableBtn) {
                        e.target.disabled = false;
                        e.target.querySelector('.loader').style.display = 'none';
                    }
                })
            } else {
                modal_error.textContent = "Исправьте ошибки в полях формы";
                scrollToModal();
            }
        })
    });
}
